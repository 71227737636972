<template>
  <section id="dashboard-main" class="mt-2">
    <b-row class="match-height">
      <!-- 장애 현황 파악 리스트 -->
      <b-col xl="12" lg="12" md="12" class="ecs-card">
        <!-- Table Container Card -->
        <b-card no-body>
          <div class="mr-1 ml-1 mt-1 mb-0">
            <b-row>
              <!-- 타이틀 -->
              <b-col cols="12" xl="12" lg="12" md="12" class="clearfix">
                <h4 class="float-left font-weight-bolder mt-8px">장애 로그</h4>
                <div
                  class="justify-content-center spinner-custom float-right ml-1"
                  :class="{ 'd-none': active }"
                >
                  <b-spinner
                    v-for="variant in variants"
                    :variant="variant"
                    :key="variant"
                  ></b-spinner>
                </div>
                <b-form-select
                  class="col-md-2 float-right"
                  v-model="selectArea"
                  size=""
                  :options="this.areaOption"
                  @change="setFilter(selectArea)"
                  placeholder="UCS List"
                />
                <flat-pickr
                  v-model="tmEnd"
                  class="form-control col-md-2 float-right mr-2"
                  :config="config"
                />
                <span class="display-inline-block float-right px-1">~</span>
                <flat-pickr
                  v-model="tm"
                  class="form-control col-md-2 float-right"
                  :config="config"
                />
              </b-col>
              <!-- 타이틀 END -->

              <!-- 탭 메뉴 -->
              <!-- <b-col
                cols="12"
                md="12"
              > 
                <b-tabs class="custom">
                  <b-tab title="All" @click="clickTab('all')">
                  </b-tab>
                  <b-tab title="info" @click="clickTab('info')">
                  </b-tab>
                  <b-tab title="Critical" @click="clickTab('critical')">
                  </b-tab>
                  <b-tab title="Major" @click="clickTab('major')">
                  </b-tab>
                  <b-tab title="Minor" @click="clickTab('minor')">
                  </b-tab>
                  <b-tab title="Warning" @click="clickTab('warning')">
                  </b-tab>
                  <b-tab title="Cleared" @click="clickTab('cleared')">
                  </b-tab>
                </b-tabs>
              </b-col> -->
              <!-- 검색 박스 / 버튼 -->
            </b-row>
          </div>
          <div class="">
            <b-row class="mr-1 ml-1 justify-content-end">
              <b-col md="2" class=""> </b-col>
            </b-row>
          </div>
          <!-- <div class="postion-relative text-center table-noview table-responsive">
            <table class=""></table>      
          </div> -->
          <div class="float-left p-1">
            <input
              type="checkbox"
              class="m-r-5"
              checked
              name="allCheck"
              id="allCheck"
              @click="cAll('all')"
            />
            <label for="allCheck" class="text-gray p-r-5">All</label>
            <!-- <input type="checkbox" class="m-r-5" name="info" id="infoCheck" @click="cPart('info')" />
              <label for="infoCheck" class="text-gray p-r-5">Info</label> -->
            <input
              type="checkbox"
              class="m-r-5"
              name="critical"
              id="criticalCheck"
              @click="cPart('critical')"
            />
            <label for="criticalCheck" class="text-critical p-r-5"
              >Critical</label
            >
            <input
              type="checkbox"
              class="m-r-5"
              name="major"
              id="majorCheck"
              @click="cPart('major')"
            />
            <label for="majorCheck" class="text-major p-r-5">Major</label>
            <input
              type="checkbox"
              class="m-r-5"
              name="minor"
              id="minorCheck"
              @click="cPart('minor')"
            />
            <label for="minorCheck" class="text-minor p-r-5">Minor</label>
            <input
              type="checkbox"
              class="m-r-5"
              name="warning"
              id="warningCheck"
              @click="cPart('warning')"
            />
            <label for="warningCheck" class="text-e-warning p-r-5"
              >Warning</label
            >
            <input
              type="checkbox"
              class="m-r-5"
              name="cleared"
              id="clearedCheck"
              @click="cPart('cleared')"
            />
            <label for="clearedCheck" class="text-e-warning p-r-5"
              >Cleared</label
            >
          </div>
          <div class="pl-1 pr-1">
            <vue-good-table
              :columns="ServObNtColumns"
              :rows="this.resultList"
              :rtl="direction"
              v-dragscroll="{ target: '.vgt-responsive' }"
              :search-options="{
                enabled: false,
                externalQuery: searchTerm,
              }"
              :pagination-options="{
                enabled: true,
                perPage: pageLength,
              }"
              theme="my-theme"
              class="table good-table-custom table-light"
              @on-row-click="onRowClick"
            >
              <div slot="emptystate" class="text-center">
                <span v-if="active === true">조회된 목록이 없습니다.</span>
                <span :class="{ 'd-none': active }">
                  <b-skeleton animation="wave" width="100%"></b-skeleton>
                  <b-skeleton animation="wave" width="85%"></b-skeleton>
                  <b-skeleton animation="wave" width="60%"></b-skeleton>
                  <b-skeleton animation="wave" width="93%"></b-skeleton>
                  <b-skeleton animation="wave" width="45%"></b-skeleton>
                </span>
              </div>
              <template slot="table-row" slot-scope="props">
                <!-- Column: Name -->
                <div v-if="props.column.field === 'sn'" class="text-nowrap">
                  {{ props.row.originalIndex + 1 }}
                </div>
                <div v-if="props.column.field === 'name'" class="text-nowrap">
                  {{ props.formattedRow.name }}
                </div>
                <div v-if="props.column.field === 'ip'" class="text-nowrap">
                  {{ props.formattedRow.ip }}
                </div>
                <div
                  v-if="props.column.field === 'severity'"
                  class="text-nowrap"
                >
                  {{ props.formattedRow.severity }}
                </div>
                <div
                  v-if="props.column.field === 'affected'"
                  class="text-nowrap"
                >
                  <span v-if="props.formattedRow.affected">{{
                    props.formattedRow.affected
                  }}</span>
                  <span v-else>{{ props.row.dn }}</span>
                </div>
                <div v-if="props.column.field === 'code'" class="text-nowrap">
                  {{ props.formattedRow.code }}
                </div>
                <div v-if="props.column.field === 'id'" class="text-nowrap">
                  {{ props.formattedRow.id }}
                </div>
                <div v-if="props.column.field === 'cause'" class="text-nowrap">
                  {{ props.formattedRow.cause }}
                </div>

                <div v-if="props.column.field === 'user'" class="text-nowrap">
                  {{ props.formattedRow.user }}
                </div>
                <div v-if="props.column.field === 'descr'" class="text-nowrap">
                  {{ props.formattedRow.descr }}
                </div>
                <div
                  v-if="props.column.field === 'created'"
                  class="text-nowrap"
                >
                  {{ props.formattedRow.created }}
                </div>
                <div v-if="props.column.field === 'currTm'" class="text-nowrap">
                  {{ props.formattedRow.currTm }}
                </div>
                <!-- <div
                    v-if="props.column.field === 'setting'"
                    class="text-nowrap"
                    >
                    <a
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      v-b-toggle.serv-log-detail
                      size="sm"
                      @click="detailProps(items[props.row.originalIndex])"
                      >
                      <button
                      style="border: 1px solid #eee; border-radius: 10px; padding: 8px; font-size : 10px;"
                      size="sm"
                      >
                      상세
                      </button>
                    </a>
                  </div> -->
              </template>
              <template slot="pagination-bottom" slot-scope="props">
                <div class="d-flex justify-content-end flex-wrap">
                  <div>
                    <b-pagination
                      :value="1"
                      :total-rows="props.total"
                      :per-page="pageLength"
                      first-number
                      last-number
                      align="right"
                      prev-class="prev-item"
                      next-class="next-item"
                      class="m-2"
                      @input="
                        (value) => props.pageChanged({ currentPage: value })
                      "
                    >
                      <template #prev-text>
                        <feather-icon icon="ChevronLeftIcon" size="18" />
                      </template>
                      <template #next-text>
                        <feather-icon icon="ChevronRightIcon" size="18" />
                      </template>
                    </b-pagination>
                  </div>
                </div>
              </template>
            </vue-good-table>
            <!-- <serv-log-detail :detailItems="this.detailItemObj"></serv-log-detail> -->
            <serv-ob-nt-detail
              :detailItems="this.detailItem"
            ></serv-ob-nt-detail>
          </div>
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>
<style>
.badge-critical {
  background-color: #ea5455 !important;
}
.badge-major {
  background-color: #ff9f43 !important;
}
.badge-minor {
  background-color: #ffd500 !important;
}
.badge-warning {
  background-color: #00967e !important;
}
.badge-info {
  background-color: #616161 !important;
}
</style>
<script>
// import ucsData from '@/assets/api/ucs.ts'
import Ripple from "vue-ripple-directive";

import {
  BCard,
  BRow,
  BCol,
  BTable,
  BFormSelect,
  BButton,
  VBToggle,
  BSpinner,
  BSkeleton,
  BPagination,
  BTabs,
  BTab,
  BBadge,
  BFormInput,
  BFormDatepicker,
} from "bootstrap-vue";

import store from "@/store/index";

import { dragscroll } from "vue-dragscroll";

import { VueGoodTable } from "vue-good-table";

// import ServLogDetail from './components/ServLogDetail.vue'
import ServObNtDetail from "./components/ServObNtDetail.vue";

import flatPickr from "vue-flatpickr-component";
import { Korean } from "flatpickr/dist/l10n/ko.js";

const qs = require("qs");

export default {
  directives: {
    Ripple,
    dragscroll,
    "b-toggle": VBToggle,
  },
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BSpinner,
    BTable,
    // BAvatar,
    BSkeleton,
    VueGoodTable,
    BFormSelect,
    BButton,
    BPagination,
    BTabs,
    BTab,
    BBadge,
    flatPickr,
    BFormDatepicker,
    // ServLogDetail,
    ServObNtDetail,
  },
  data() {
    return {
      ucsServerUrl: "/ucsmaster/selectUcsMasterListAllJson",
      ucsUrl: "ucsapi/nuova",
      config: {
        enableTime: true,
        allowInput: false,
        allowInvalidPreload: false,
        dateFormat: "Y-m-d H:i",
        locale: Korean,
        maxDate: this.setTime(0),
        allowInput: true,
      },
      data: {
        items: [],
        totalInvoices: 0,
      },
      pageLength: 10,
      searchTerm: "",
      checkType: "all",
      type: "all",
      variants: ["primary"],
      active: true,
      perPage: 10,
      currentPage: 1,
      perPageOptions: [10, 25, 50, 100],
      itemLength: 0,
      selectArea: 0,
      areaOption: [{ text: "전체 서버", value: 0 }],
      ServObNtColumns: [
        {
          field: "sn",
          label: "번호",
          sortable: true,
          sortFn: this.sortFn,
          width: "60px",
        },
        {
          field: "name",
          label: "그룹망 이름",
          sortable: true,
          sortFn: this.sortFn,
          filterOptions: {
            enabled: true,
            placeholder: "그룹망 이름",
          },
        },
        {
          field: "ip",
          label: "그룹망 IP",
          sortable: true,
          sortFn: this.sortFn,
          filterOptions: {
            enabled: true,
            placeholder: "그룹망 IP",
          },
        },
        {
          field: "id",
          label: "id",
          sortable: true,
          sortFn: this.sortFn,
          filterOptions: {
            enabled: true,
            placeholder: "ID",
          },
        },
        {
          field: "severity",
          label: "severity",
          sortable: true,
          sortFn: this.sortFn,
          filterOptions: {
            enabled: true,
            placeholder: "SEVERITY",
          },
        },
        {
          field: "affected",
          label: "Affected object",
          sortable: true,
          sortFn: this.sortFn,
          filterOptions: {
            enabled: true,
            placeholder: "AFFECTED OBJECT",
          },
        },
        {
          field: "code",
          label: "code",
          sortable: true,
          sortFn: this.sortFn,
          filterOptions: {
            enabled: true,
            placeholder: "CODE",
          },
        },
        {
          field: "cause",
          label: "Cause",
          sortable: true,
          sortFn: this.sortFn,
          filterOptions: {
            enabled: true,
            placeholder: "CAUSE",
          },
        },
        {
          field: "user",
          label: "user",
          sortable: true,
          sortFn: this.sortFn,
          filterOptions: {
            enabled: true,
            placeholder: "USER",
          },
        },
        {
          field: "descr",
          label: "description",
          sortable: true,
          sortFn: this.sortFn,
          filterOptions: {
            enabled: true,
            placeholder: "DESCRIPTION",
          },
        },
        {
          field: "created",
          label: "발생시점",
          sortable: true,
          sortFn: this.sortFn,
        },
        {
          field: "currTm",
          label: "등록시점",
          sortable: true,
          sortFn: this.sortFn,
        },
      ],
      items: [],
      resultList: [],
      totalList: [],
      // 체크박스 변수
      checkItems: [],
      checkItem: [],
      payload: [],
      infoItem: [],
      criticalItem: [],
      majorItem: [],
      minorItem: [],
      warningItem: [],
      clearedItem: [],
      detailItem: {},
      // detailItemObj: {},
      tm: this.setTime(1),
      tmSet: this.setTime(1),
      tmEnd: this.setTime(0),
      tmEndSet: this.setTime(0),
      severitySearchString: [
        // {xmlGb: 'UCSGBS0001'},
        { xmlGb: "UCSGBS0002" },
        { xmlGb: "UCSGBS0003" },
        { xmlGb: "UCSGBS0004" },
        { xmlGb: "UCSGBS0005" },
        { xmlGb: "UCSGBS0006" },
      ],
      tabType: "all",
      checkActive: false,
      unSeverityList: [],
      copyObj: [],
      sum: 0,
    };
  },
  watch: {
    /**
     * 시간별 data filtering (starttime)
     * @create 2021-04-15
     * @author 정한슬
     * 시간 선택 시 해당 조건에 맞는 data filtering
     */
    tm: function (val) {
      // 시작기간의 변동이 없을 시 아무 동작 안함
      if (this.tmSet === this.tm) {
      } else {
        // 시작기간의 변동이 있을 시 DB 조회
        this.setTm();
        this.getServLog();
      }
      // 1시간 기준으로 filter 조회
      // let sdata = new Array()
      // sdata = this.copyObj.filter( (x) => {
      //   if(x.currTm) return x.currTm >= this.tm && this.tmEnd >= x.currTm
      //   else if(x.tm) return x.tm >= this.tm && this.tmEnd >= x.tm
      // })

      // // Table에 바인딩시키기 위한 변수
      // this.resultList = sdata
      // this.resultList.sort(function (a,b){
      //   return b.id - a.id
      // })

      // // resultList를 초기화시키기 위한 변수
      // this.items = sdata
      // this.items.sort(function (a,b){
      //   return b.id - a.id
      // })
    },
    /**
     * 시간별 data filtering (endtime)
     * @create 2021-04-15
     * @author 정한슬
     * 시간 선택 시 해당 조건에 맞는 data filtering
     */
    tmEnd: function (val) {
      // 종료기간이 시작기간보다 빠를 시.
      if (this.tm > this.tmEnd) {
        // this.tm = this.setTime(1)
        this.tmEnd = this.setTime(0);
        alert("종료기간이 시작기간보다 빠릅니다.");
        return false;
      } else if (this.tmEndSet === this.tmEnd) {
        // 종료기간의 변동이 있을 시 DB 조회
      } else {
        // 종료기간의 변동이 있을 시 DB 조회
        this.setTm();
        this.getServLog();
      }
      // if (this.tm > this.tmEnd) {
      //   // this.tm = this.setTime(1)
      //   this.tmEnd = this.setTime(0)
      //   alert("종료기간이 시작기간보다 빠릅니다.")
      //   return false
      // } else {
      //   let edata = new Array()
      //   edata = this.copyObj.filter( (x) => {
      //     if(x.currTm) return x.currTm >= this.tm && this.tmEnd >= x.currTm
      //     else if(x.tm) return x.tm >= this.tm && this.tmEnd >= x.tm
      //   })

      //   // Table에 바인딩시키기 위한 변수
      //   this.resultList = edata
      //   this.resultList.sort(function (a,b){
      //     return b.id - a.id
      //   })

      //   // resultList를 초기화시키기 위한 변수
      //   this.items = edata
      //   this.items.sort(function (a,b){
      //     return b.id - a.id
      //   })
      // }
    },
  },
  created() {
    this.tm = this.setTime(1);
    this.tmEnd = this.setTime(0);
    // this.getServLog()
  },
  mounted() {
    this.getServer(); // 첫 페이지 로드 시 서버 리스트 조회
    // this.getServAll() // 첫 페이지 로드 시 상세 정보 조회
    this.cAll(); // 첫 페이지 로드 시 체크박스 전체 체크
    this.getServLog(); // 첫 페이지 로드 시 DB 전체 조회
    this.setTm();
  },
  methods: {
    /**
     * UCS 서버 리스트 조회
     * @created 2021-05-20
     * @author 박재헌
     * @description UCS 서버 리스트 조회 후 셀렉트 검색 기능 활성화
     */
    getServer() {
      this.$jBack({
        method: "post",
        url: this.ucsServerUrl,
      }).then((response) => {
        const serverList = response.data.resultList;
        this.areaOption = [{ value: 0, text: "전체 서버" }];
        for (let index = 0; index < serverList.length; index++) {
          const element = serverList[index];
          this.areaOption.push({ value: element.sn, text: element.name });
        }
      });
    },
    /**
     * Tm 검색 후 watch 감지용 tmSet / tmEndSet
     * @created 2021-05-20
     * @author 박재헌
     * @description 각 Tm 및 TmEnd 값 변동 없을 시 DB 검색 안되도록하기 위한 세팅
     */
    setTm() {
      this.tmSet = this.tm;
      this.tmEndSet = this.tmEnd;
      // console.log(this.tmSet, this.tm)
    },
    /**
     * 탭버튼 조회 clickTab()
     * @create 2021-04-13
     * @author 박재헌
     * 각 탭 버튼 클릭 시 해당 조건에 맞는 장애현황 조회
     */
    clickTab(severity) {
      const checkbox = document.getElementById("allCheck");
      // active된 탭을 한번 더 클릭 했을 때 필터를 또 실행시키지않도록 분기처리
      if (this.tabType != severity) {
        // tabType이 인자와 다를 시 tabType 변경
        this.tabType = severity;
        // Table 바인딩 변수 초기화
        this.resultList = this.items;
        // 전체 All 탭 클릭 시 전체 리스트 볼 수 있도록 분기처리
        if (severity === "all") {
          this.checkActive = false;
          // all 체크박스 true
          checkbox.checked = true;
          this.cAll();
          // unSeverityList 초기화
          this.unSeverityList = [];
        } else {
          this.checkActive = true;
          // All 탭이 아닐 시 해당 탭에 맞게 resultList 필터
          this.resultList = this.resultList.filter((x) => {
            return x.severity === severity;
          });
        }
      } else {
      }
    },
    /**
     * 이벤트 로그 현황
     * @create 2021-04-13
     * @author 박재헌
     * @returns {json}
     * @description 이벤트 로그 현황
     */
    getServLog() {
      this.resultList = [];
      this.items = [];
      this.active = false;
      let param = {
        tm: this.tm,
        tmEnd: this.tmEnd,
        tbUcsMasterSn: this.selectArea,
        severitySearchString: this.severitySearchString,
      };
      param.severitySearchString = param.severitySearchString
        ? JSON.stringify(param.severitySearchString)
        : [];
      this.$jBack({
        method: "post",
        url: "/ucsxmlmaster/selectUcsXmlMasterFaultList",
        data: qs.stringify(param),
        headers: {
          "X-Requested-With": "XMLHttpRequest",
          "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
        },
      })
        .then((response) => {
          // console.log(response)
          let items = response.data.resultList;
          // items.reverse()
          // console.log(items)
          // console.log("succues 7")
          let itemsJSON = new Array();
          for (let index = 0; index < items.length; index++) {
            // console.log(items[index])
            const element = items[index];
            itemsJSON.push(JSON.parse(element.plainXml));
            // console.log(itemsJSON)
          }
          // console.log("succues 6")
          // obj 복사
          // this.copyObj = this.deepCopy(itemsJSON)
          // console.log("succues 5")
          // 1시간 기준으로 filter 조회
          itemsJSON = itemsJSON.filter((x) => {
            if (x.currTm) return x.currTm >= this.tm && this.tmEnd >= x.currTm;
            else if (x.tm) return x.tm >= this.tm && this.tmEnd >= x.tm;
          });
          // console.log("succues 4")

          // 중복 제거
          // let payload = itemsJSON
          // itemsJSON = this.getUniqueObjectArray(payload, 'id', 'sn')
          // console.log(itemsJSON)

          // Table에 바인딩시키기 위한 변수
          this.resultList = itemsJSON;
          this.resultList.sort(function (a, b) {
            return b.id - a.id;
          });
          // console.log("succues 3")

          // resultList를 초기화시키기 위한 변수
          this.items = itemsJSON;

          this.items.sort(function (a, b) {
            return b.id - a.id;
          });
          // console.log("succues 1")
          // 체크박스 체크해제 후 날짜 재 조회 시 해당 필터로 조회
          if (
            this.unSeverityList != [] ||
            this.unSeverityList != undefined ||
            this.unSeverityList != null
          ) {
            for (let index = 0; index < this.unSeverityList.length; index++) {
              const element = this.unSeverityList[index];
              this.resultList = this.resultList.filter((x) => {
                return x.severity !== element;
              });
            }
          }
          // console.log("succues 2")
          // console.log(this.resultList)
          // console.log(this.resultList)

          // loading
          this.active = true;

          // pagenation
          // this.totalPageCount = response.data.paginationInfo.totalPageCount
          // this.totalRecordCount = response.data.paginationInfo.totalRecordCount
        })
        .catch((error) => {
          this.active = true;
        });
    },
    setFilter(data) {
      // this.$set(this.ServObNtColumns[2].filterOptions, 'filterValue', data);
      this.getServLog();
    },
    getUniqueObjectArray(array, key) {
      return array.filter((item, i) => {
        return (
          array.findIndex((item2, k) => {
            return item[key] === item2[key];
          }) === i
        );
      });
    },
    /**
     * 체크박스 이벤트 cAll()
     * @author 박재헌
     * @create 2021-04-13
     * 첫 페이지 또는 탭간 이동 시 전체 체크박스 true 및 resultList 조회
     */
    cAll(type) {
      this.checkType = type;
      // All 체크박스 지역변수
      const checkbox = document.getElementById("allCheck");
      // 전체 체크박스 리스트 지역변수
      const checkboxType = document.getElementsByTagName("input");
      // All 체크박스를 제외한 타입별 체크박스 리스트 지역변수
      const checkList = [];
      for (let index = 0; index < checkboxType.length; index++) {
        const element = checkboxType[index];
        // All 체크박스를 제외한 타입별 체크박스 리스트 지역변수 PUSH
        if (element.type === "checkbox" && element.id != "allCheck") {
          checkList.push(element);
        }
      }

      if (checkbox.checked === true) {
        // All 체크박스 true 시 전체 체크박스 true
        for (let index = 0; index < checkList.length; index++) {
          const element = checkList[index];
          element.checked = true;
        }

        // 장애현황조회 전체 조회
        this.unSeverityList = [];
        this.resultList = this.items;
      } else {
        // All 체크박스 true 일 때 클릭시 전체 체크박스 해제
        for (let index = 0; index < checkList.length; index++) {
          const element = checkList[index];
          if (element.type === "checkbox" && element.id != "allCheck") {
            element.checked = false;
          }
        }
        // 장애현황조회 초기화
        this.unSeverityList = [
          "critical",
          "major",
          "minor",
          "warning",
          "cleared",
        ];
        this.resultList = [];
      }
      // this.getServLog()
    },
    /**
     * 체크박스 타입별 이벤트 cPart()
     * @create 2021-04-13
     * @author 박재헌
     * 체크박스별 장애현황 조회
     */
    cPart(type) {
      this.checkType = type;
      let checkbox = "";
      let checkAll = document.getElementById("allCheck");
      // 전체 리스트에서 unSeverityList에 들어있는 정보를 필터하기위해 리스트 초기화
      this.resultList = this.items;

      // 해당 체크박스의 타입 체크 후 checkbox 지역변수에 담기
      if (type === "all") {
      } else if (type === "info") {
        checkbox = document.getElementById("infoCheck");
      } else if (type === "critical") {
        checkbox = document.getElementById("criticalCheck");
      } else if (type === "major") {
        checkbox = document.getElementById("majorCheck");
      } else if (type === "minor") {
        checkbox = document.getElementById("minorCheck");
      } else if (type === "warning") {
        checkbox = document.getElementById("warningCheck");
      } else if (type === "cleared") {
        checkbox = document.getElementById("clearedCheck");
      }

      if (checkbox.checked === true) {
        // 해당 체크박스 해제 시 unSeverityList 제거
        if (checkbox === document.getElementById("infoCheck")) {
          this.unSeverityList = this.unSeverityList.filter((x) => {
            return x != "info";
          });
        } else if (checkbox === document.getElementById("criticalCheck")) {
          this.unSeverityList = this.unSeverityList.filter((x) => {
            return x != "critical";
          });
        } else if (checkbox === document.getElementById("majorCheck")) {
          this.unSeverityList = this.unSeverityList.filter((x) => {
            return x != "major";
          });
        } else if (checkbox === document.getElementById("minorCheck")) {
          this.unSeverityList = this.unSeverityList.filter((x) => {
            return x != "minor";
          });
        } else if (checkbox === document.getElementById("warningCheck")) {
          this.unSeverityList = this.unSeverityList.filter((x) => {
            return x != "warning";
          });
        } else if (checkbox === document.getElementById("clearedCheck")) {
          this.unSeverityList = this.unSeverityList.filter((x) => {
            return x != "cleared";
          });
        } else {
        }
        this.cPartAll();
      } else {
        checkAll.checked = false;
        // 해당 체크박스 해제 시 unSeverityList 추가
        if (checkbox === document.getElementById("infoCheck")) {
          this.unSeverityList.push("info");
        } else if (checkbox === document.getElementById("criticalCheck")) {
          this.unSeverityList.push("critical");
        } else if (checkbox === document.getElementById("majorCheck")) {
          this.unSeverityList.push("major");
        } else if (checkbox === document.getElementById("minorCheck")) {
          this.unSeverityList.push("minor");
        } else if (checkbox === document.getElementById("warningCheck")) {
          this.unSeverityList.push("warning");
        } else if (checkbox === document.getElementById("clearedCheck")) {
          this.unSeverityList.push("cleared");
        } else {
        }
      }
      // console.log(this.unSeverityList)
      // console.log(this.resultList)
      // 체크박스 체크해제 false 시 unSeverityList에 해당 severity를 담은 후 !== filter로 필터
      if (
        this.unSeverityList != [] ||
        this.unSeverityList != undefined ||
        this.unSeverityList != null
      ) {
        for (let index = 0; index < this.unSeverityList.length; index++) {
          const element = this.unSeverityList[index];
          this.resultList = this.resultList.filter((x) => {
            return x.severity !== element;
          });
        }
      }
    },
    /**
     * 체크박스 전체 체크 이벤트 cPartAll()
     * @create 2021-04-13
     * @author 박재헌
     * 체크박스 전체 체크될 시 All 체크박스 체크
     */
    cPartAll() {
      let count = 0;
      // 전체 체크박스 리스트 지역변수
      const checkboxType = document.getElementsByTagName("input");
      // All 체크박스를 제외한 체크박스 리스트 지역변수
      const checkList = [];
      for (let index = 0; index < checkboxType.length; index++) {
        const element = checkboxType[index];
        // All 체크박스를 제외한 체크박스 리스트 지역변수 PUSH
        if (element.type === "checkbox" && element.id != "allCheck") {
          checkList.push(element);
        }
      }
      // All 체크박스
      let checkAll = document.getElementById("allCheck");
      // 체크박스 전체 갯수 true 체크 후 count
      for (let index = 0; index < checkList.length; index++) {
        const element = checkList[index];
        if (element.checked == true) {
          count++;
        }
      }
      // All 체크박스를 제외한 나머지 체크박스가 전체 true 일 시 All 체크박스 true
      if (count == checkList.length) {
        checkAll.checked = true;
      }
    },
    deepCopy(obj) {
      var copy;

      // Handle the 3 simple types, and null or undefined
      if (null == obj || "object" != typeof obj) return obj;

      // Handle Date
      if (obj instanceof Date) {
        copy = new Date();
        copy.setTime(obj.getTime());
        return copy;
      }

      // Handle Array
      if (obj instanceof Array) {
        copy = [];
        for (var i = 0, len = obj.length; i < len; i++) {
          copy[i] = this.deepCopy(obj[i]);
        }
        return copy;
      }

      // Handle Object
      if (obj instanceof Object) {
        copy = {};
        for (var attr in obj) {
          if (obj.hasOwnProperty(attr)) copy[attr] = this.deepCopy(obj[attr]);
        }
        return copy;
      }
      throw new Error("Unable to copy obj! Its type isn't supported.");
    },
    /**
     * 페이지 로드 시 시간 갱신
     * @create 2021-04-13
     * @author 박재헌
     * 시간 갱신 setTime
     */
    setTime(h) {
      const time = new Date();
      const year = time.getFullYear();
      const month = time.getMonth() + 1;
      const day = time.getDate();
      let hour = time.getHours();
      if (time.getHours() == 0 && h == 1) {
        hour = 23;
      } else {
        hour = time.getHours() - h;
      }
      const minutes = time.getMinutes();
      const seconds = time.getSeconds();
      let s = "";
      if (hour < 24) {
        // s = `${year<10 ? `0${year}`:year}-${month<10 ? `0${month}`:month}-${day<10 ? `0${day}`:day} ${hour<10 ? `0${hour}`:hour}:${minutes<10 ? `0${minutes}`:minutes}:${seconds<10 ? `0${seconds}`:seconds}`
        s = `${year < 10 ? `0${year}` : year}-${
          month < 10 ? `0${month}` : month
        }-${day < 10 ? `0${day}` : day} ${hour < 10 ? `0${hour}` : hour}:${
          minutes < 10 ? `0${minutes}` : minutes
        }`;
      } else {
        const times = new Date();
        const years = times.getFullYear();
        const months = times.getMonth() + 1;
        const days = times.getDate();
        const hours = times.getHours();
        const minutess = times.getMinutes();
        const secondss = times.getSeconds();
        // s = `${years<10 ? `0${years}`:years}-${months<10 ? `0${months}`:months}-${days<10 ? `0${days}`:days} ${hours<10 ? `0${hours}`:hours}:${minutess<10 ? `0${minutess}`:minutess}:${secondss<10 ? `0${secondss}`:secondss}`
        s = `${years < 10 ? `0${years}` : years}-${
          months < 10 ? `0${months}` : months
        }-${days < 10 ? `0${days}` : days} ${
          hours < 10 ? `0${hours}` : hours
        }:${minutess < 10 ? `0${minutess}` : minutess}`;
      }
      // console.log(s)
      return s;
    },
    /**
     * 서버 전체 목록
     * @create 2021-04-08
     * @author 정한슬
     * @returns {json}
     * @description 서버 전체 목록
     */
    // getServAll() {
    //   // this.active = false
    //   this.$ucsNnBackode.post(this.ucsUrl, {
    //     anuova: 5,
    //     apiGb: 'all',
    //   })
    //   .then(response => {
    //     // console.log(response.data)
    //     // this.active = true
    //     if (response.data.resultCode == 'success') {
    //       this.detailItem = response.data.data
    //     } else {
    //       this.detailItem = []
    //     }
    //   })
    // },
    /**
     * 상세 슬라이드 open
     * @create 2021-04-14
     * @author 정한슬
     * @description 상세 슬라이드 open
     */
    onRowClick(data) {
      this.detailItem = data.row;
      this.$root.$emit("bv::toggle::collapse", "serv-ob-nt-detail");
      // this.detailItemObj = {}
      // if (this.detailItem.length) {
      //   console.log(data.row.affected)
      //   if(data.row.affected || data.row.ack){
      //     console.log('1')

      //     for (let d = 0; this.detailItem.length > d; d++) {
      //       console.log(data.row.affected)
      //       console.log(data.row.dn)
      //       console.log(data.row.sn, this.detailItem[d].sn)
      //       if (data.row.affected == this.detailItem[d].dn && data.row.sn == this.detailItem[d].sn) {
      //         console.log('2')
      //         this.detailItemObj = this.detailItem[d]
      //       }
      //     }
      //     this.$root.$emit('bv::toggle::collapse', 'serv-log-detail')
      //   }else{
      //     alert("상세조회가 불가능한 로그입니다.")
      //   }
      // }
      // console.log(this.detailItem)
      // console.log(data.row)
    },
  },
  computed: {
    rows() {
      return this.items.length;
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true;
        return this.dir;
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false;
      return this.dir;
    },
  },
};
</script>

<style lang="scss">
th,
td {
  text-align: center;
  white-space: nowrap;
}
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-good-table.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
